import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/tires-auto/Templates/SearchPage.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { RequestPanel } from 'Components'
import TemplateHomeTireBar from 'Stores/tires-auto/Templates/HomeTireBar.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import TemplatefitmentSearchcollapsibleVerifyFitment from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVerifyFitment.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
SearchBox,
SearchBoxDialogButton,
FacetPanel,
FacetBar,
FacetTiles,
VehicleWidget,
RequestPanel,
Garage,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'HomeTireBar': TemplateHomeTireBar,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/collapsibleVerifyFitment': TemplatefitmentSearchcollapsibleVerifyFitment,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};